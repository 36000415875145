<template>
  <div class="container company-page-content">
    <div class="mt-4" v-if="loading">
      <loader></loader>
    </div>
    <div v-else class="company-pages mb-5 row mt-1 no-margin">
      <div class="col-sm-12 mt-4" v-if="data.title">
        <h3 class="title">{{ data.title }}</h3>
      </div>
      <div class="col-sm-12" :class="{ 'mt-4': !data.title, 'mt-2': data.title }">
        <component v-if="data.htmlContentOnTop && data.htmlContent" v-bind:is="htmlContent" v-bind="$props" />

        <!-- Carousel -->
        <el-carousel :interval="5000" arrow="always" v-if="data.htmlContentOnTop && slides.length">
          <el-carousel-item v-for="(item, index) in slides" :key="'slide' + index">
            <div v-html="item"></div>
          </el-carousel-item>
        </el-carousel>

        <primary-button v-if="data.htmlContentOnTop && data.formId" @click="navigate(data)">{{
          'general.view' | translate
        }}</primary-button>

        <div class="row" :class="{ 'mt-5': data.htmlContentOnTop }">
          <template v-if="data.hasChildPages">
            <div
              class="xs-top-spacing-24 pointer mb-5 company-page-images"
              v-for="item in data.childPages"
              :key="item.id"
              @click="navigate(item)"
              :class="{
                'col-12': data.childPages.length == 1,
                'col-md-6':
                  (data.childPages.length % 4 === 0 || data.childPages.length < 3) && data.childPages.length > 1,
                'col-md-4':
                  ((data.childPages.length % 3 === 0 ||
                    data.childPages.length % 3 === 2 ||
                    data.childPages.length % 2 === 1) &&
                    data.childPages.length > 2) ||
                  data.childPages.length > 8
              }"
            >
              <figure class="blur img-container embed-responsive embed-responsive-2by1">
                <img class="embed-responsive-item" :src="item.imageURL" width="100%" height="200px" />
                <div
                  class="panel-caption"
                  v-if="item.label && item.label.length"
                  :style="{
                    'background-color': style.companyPageImageCaptionBackgroundColor
                      ? style.companyPageImageCaptionBackgroundColor
                      : style.colorPrimaryButton,
                    color: style.companyPageImageCaptionTextColor
                      ? style.companyPageImageCaptionTextColor
                      : style.colorPrimaryButtonText
                  }"
                >
                  <p class="subtitle">{{ item.label }}</p>
                </div>
              </figure>
            </div>
          </template>
        </div>
        <div class="row" :class="{ 'mt-5': !data.htmlContentOnTop && data.hasChildPages }">
          <div class="col-sm-12">
            <component v-if="!data.htmlContentOnTop && data.htmlContent" v-bind:is="htmlContent" v-bind="$props" />
            <!-- Carousel -->
            <el-carousel height="800px" :interval="5000" arrow="always" v-if="!data.htmlContentOnTop && slides.length">
              <el-carousel-item v-for="(item, index) in slides" :key="'slide-' + index">
                <div v-html="item"></div>
              </el-carousel-item>
            </el-carousel>
          </div>
        </div>

        <primary-button v-if="!data.htmlContentOnTop && data.formId" @click="navigate(data)">{{
          'general.view' | translate
        }}</primary-button>
      </div>
    </div>
  </div>
</template>

<script>
import isArray from 'lodash/isArray';
import { mapGetters } from 'vuex';
import { Carousel, CarouselItem } from 'element-ui';

export default {
  components: {
    elCarousel: Carousel,
    elCarouselItem: CarouselItem
  },
  created() {
    this.$http
      .get(`dashboard/companypages/${this.$route.params.overview}/items/${this.$route.params.id}`)
      .then((response) => {
        // check for old accordions and sliders
        if (response.data && response.data.htmlContent) {
          let style = response.data.htmlContent.match(/<style([\S\s]*?)>([\S\s]*?)<\/style>/g, '');
          if (style && style.length) {
            style = style.join(' ');
            style = style
              .replace(/<style([Ss]*?)>/gi, '')
              .replace(/<\/style>/g, '')
              .replace(/<style>/g, '')
              .replace(/<style type="text\/css">/g, '')
              .replace(/\.panel-heading/g, '.el-collapse-item__header')
              .replace(/\.panel-caption/g, '.image-caption')
              .replace(/\.panel/g, '.el-collapse-item')
              .replace(/\.image-caption/g, '.panel-caption')
              .replace('body {', '.company-page-content {')
              .replace('body{', '.company-page-content {')
              .replace('*,', '')
              .replace(/\)!important;/g, ');');
            const styleEl = document.createElement('style');
            styleEl.type = 'text/css';
            styleEl.id = 'custom';
            styleEl.appendChild(document.createTextNode(style));
            const companyPageEl = document.querySelector('.company-page-content');
            companyPageEl.insertBefore(styleEl, companyPageEl.firstChild);
          }
          response.data.htmlContent = response.data.htmlContent.replace(/<p>&nbsp;<\/p>/g, "<p class='my-2'></p>");
          response.data.htmlContent = response.data.htmlContent.replace(/100=""/g, '');
          response.data.htmlContent = response.data.htmlContent.replace(/<style([\S\s]*?)>([\S\s]*?)<\/style>/g, '');

          response.data.htmlContent = response.data.htmlContent.replace(/<uib-accordion-group/g, '<el-collapse-item');
          response.data.htmlContent = response.data.htmlContent.replace(
            /<\/uib-accordion-group/g,
            '</el-collapse-item'
          );
          response.data.htmlContent = response.data.htmlContent.replace(/panel-class/g, 'class');
          response.data.htmlContent = response.data.htmlContent.replace(
            /visible-xs-block/g,
            'd-block d-md-none d-lg-none'
          );
          response.data.htmlContent = response.data.htmlContent.replace(/is-open="[a-zA-Z]+\.open"/g, '');
          response.data.htmlContent = response.data.htmlContent.replace(
            /<uib-accordion-heading/g,
            "<template slot='title'"
          );
          response.data.htmlContent = response.data.htmlContent.replace(/<\/uib-accordion-heading/g, '</template');

          response.data.htmlContent = response.data.htmlContent.replace(
            /<uib-accordion([^>]*)/g,
            '<el-collapse accordion '
          );
          response.data.htmlContent = response.data.htmlContent.replace(/<\/uib-accordion/g, '</el-collapse');
          response.data.htmlContent = response.data.htmlContent.replace(/linkedin-square/g, 'linkedin');
          response.data.htmlContent = response.data.htmlContent.replace(/youtube-play/g, 'youtube-square');
          response.data.htmlContent = response.data.htmlContent.replace(/facebook-official/g, 'facebook');

          response.data.htmlContent = response.data.htmlContent.replace(
            /<uib-carousel/g,
            "<swiper :options=\"{loop: true, navigation: {nextEl: '.swiper-button-next',prevEl: '.swiper-button-prev'}}\""
          );
          response.data.htmlContent = response.data.htmlContent.replace(
            /<\/uib-carousel/g,
            "<div class='swiper-button-prev swiper-button-black' slot='button-prev'></div><div class='swiper-button-next swiper-button-black' slot='button-next'></div></swiper"
          );
          response.data.htmlContent = response.data.htmlContent.replace(/<uib-slide/g, '<swiper-slide');
          response.data.htmlContent = response.data.htmlContent.replace(/<\/uib-slide/g, '</swiper-slide');
          response.data.htmlContent = response.data.htmlContent.replace(/ng-src/g, 'src');

          // add zoomable functionality
          if (response.data.htmlContent.indexOf('zoomable') > 0) {
            let parser = new DOMParser();
            let parsedHtml = parser.parseFromString(response.data.htmlContent, 'text/html');
            let zoomables = parsedHtml.querySelectorAll('.zoomable');
            function wrap(el, wrapper) {
              el.parentNode.insertBefore(wrapper, el);
              wrapper.appendChild(el);
            }

            const zoomableOptions = {
              button: true,
              navbar: false,
              title: false,
              toolbar: {
                zoomIn: true,
                zoomOut: true,
                oneToOne: false,
                reset: true,
                prev: false,
                play: false,
                next: false,
                rotateLeft: false,
                rotateRight: false,
                flipHorizontal: false,
                flipVertical: false
              },
              tooltip: false,
              movable: true,
              zoomable: true,
              rotatable: false,
              scalable: true,
              transition: true,
              fullscreen: true,
              keyboard: true
            };

            zoomables.forEach((img) => {
              let div = document.createElement('div');
              div.className += 'images';
              div.setAttribute('v-viewer.static', JSON.stringify(zoomableOptions));
              wrap(img, div);
            });
            response.data.htmlContent = parsedHtml.body.innerHTML;
          }
        }

        this.data = response.data;
        this.loading = false;
        return;
      })
      .catch((err) => {
        this.$notify({
          type: 'error',
          message: this.$t('notifications.error'),
          position: 'bottom-right'
        });
        this.loading = false;
        // go back by one record, the same as history.back()
        this.$router.go(-1);
      });
  },
  computed: {
    ...mapGetters('settings', ['style']),
    htmlContent() {
      return {
        template: "<div class='d-block html-content'>" + this.data.htmlContent + '</div>',
        props: this.$options.props
      };
    }
  },
  data() {
    return {
      activeName: '1',
      data: {},
      loading: true,
      slides: []
    };
  },
  methods: {
    navigate(item) {
      if (item.formId) {
        if (item.id != this.$route.params.id) {
          // ^ do not strict check ... params are strings.
          this.$http.get(`dashboard/companypages/${this.$route.params.overview}/items/${item.id}`).then(
            (response) => {
              if (response.data && (response.data.description || response.data.htmlContent)) {
                return this.$router.push({
                  name: 'companyPageContent',
                  params: { overview: this.$route.params.overview, id: item.id }
                });
              }
              return this.$router.push({ name: 'legacyForm', params: { id: item.formId } });
            },
            (err) => {
              this.$router.push({ name: 'legacyForm', params: { id: item.formId } });
            }
          );
        } else {
          this.$router.push({ name: 'legacyForm', params: { id: item.formId } });
        }
      } else {
        this.$router.push({
          name: 'companyPageContent',
          params: { overview: this.$route.params.overview, id: item.id }
        });
      }
    }
  }
};
</script>

<style lang="scss">
.company-page-content {
  blockquote {
    padding: 11.5px 23px;
    margin: 0 0 23px;
    font-size: 16.25px;
    border-left: 5px solid #eeeeee;
  }

  .page-header h2#timeline {
    font-size: 20px;
  }

  p {
    margin: 1rem 0;
  }

  .timeline li {
    list-style: none;
  }

  .pull-left {
    float: left;
  }

  .pull-right {
    float: right;
  }

  .el-collapse {
    margin-bottom: 1rem;
    margin-top: 1rem;
    width: 100%;
  }

  .row.table {
    display: flex;
  }

  img {
    max-width: 100%;
  }

  .el-collapse-item__header {
    height: 100%;
    padding: 0 18px;
    i.fa {
      display: none;
    }
  }

  .el-collapse-item__content {
    padding: 18px;
    position: relative;
    &:before,
    &::after {
      content: ' ';
      display: table;
    }
  }

  .swiper-container {
    margin: 2rem 0;
  }

  .swiper-slide {
    img {
      width: 100%;
    }
    p {
      text-align: center;
    }
  }
}

.company-page-content {
  .swiper-button-next,
  .swiper-button-prev {
    filter: hue-rotate(90deg) brightness(150%) contrast(10%);
  }

  .panel-caption p.subtitle {
    margin: 0;
  }
}
</style>
