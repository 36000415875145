var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container company-page-content"},[(_vm.loading)?_c('div',{staticClass:"mt-4"},[_c('loader')],1):_c('div',{staticClass:"company-pages mb-5 row mt-1 no-margin"},[(_vm.data.title)?_c('div',{staticClass:"col-sm-12 mt-4"},[_c('h3',{staticClass:"title"},[_vm._v(_vm._s(_vm.data.title))])]):_vm._e(),_c('div',{staticClass:"col-sm-12",class:{ 'mt-4': !_vm.data.title, 'mt-2': _vm.data.title }},[(_vm.data.htmlContentOnTop && _vm.data.htmlContent)?_c(_vm.htmlContent,_vm._b({tag:"component"},'component',_vm.$props,false)):_vm._e(),(_vm.data.htmlContentOnTop && _vm.slides.length)?_c('el-carousel',{attrs:{"interval":5000,"arrow":"always"}},_vm._l((_vm.slides),function(item,index){return _c('el-carousel-item',{key:'slide' + index},[_c('div',{domProps:{"innerHTML":_vm._s(item)}})])}),1):_vm._e(),(_vm.data.htmlContentOnTop && _vm.data.formId)?_c('primary-button',{on:{"click":function($event){return _vm.navigate(_vm.data)}}},[_vm._v(_vm._s(_vm._f("translate")('general.view')))]):_vm._e(),_c('div',{staticClass:"row",class:{ 'mt-5': _vm.data.htmlContentOnTop }},[(_vm.data.hasChildPages)?_vm._l((_vm.data.childPages),function(item){return _c('div',{key:item.id,staticClass:"xs-top-spacing-24 pointer mb-5 company-page-images",class:{
              'col-12': _vm.data.childPages.length == 1,
              'col-md-6':
                (_vm.data.childPages.length % 4 === 0 || _vm.data.childPages.length < 3) && _vm.data.childPages.length > 1,
              'col-md-4':
                ((_vm.data.childPages.length % 3 === 0 ||
                  _vm.data.childPages.length % 3 === 2 ||
                  _vm.data.childPages.length % 2 === 1) &&
                  _vm.data.childPages.length > 2) ||
                _vm.data.childPages.length > 8
            },on:{"click":function($event){return _vm.navigate(item)}}},[_c('figure',{staticClass:"blur img-container embed-responsive embed-responsive-2by1"},[_c('img',{staticClass:"embed-responsive-item",attrs:{"src":item.imageURL,"width":"100%","height":"200px"}}),(item.label && item.label.length)?_c('div',{staticClass:"panel-caption",style:({
                  'background-color': _vm.style.companyPageImageCaptionBackgroundColor
                    ? _vm.style.companyPageImageCaptionBackgroundColor
                    : _vm.style.colorPrimaryButton,
                  color: _vm.style.companyPageImageCaptionTextColor
                    ? _vm.style.companyPageImageCaptionTextColor
                    : _vm.style.colorPrimaryButtonText
                })},[_c('p',{staticClass:"subtitle"},[_vm._v(_vm._s(item.label))])]):_vm._e()])])}):_vm._e()],2),_c('div',{staticClass:"row",class:{ 'mt-5': !_vm.data.htmlContentOnTop && _vm.data.hasChildPages }},[_c('div',{staticClass:"col-sm-12"},[(!_vm.data.htmlContentOnTop && _vm.data.htmlContent)?_c(_vm.htmlContent,_vm._b({tag:"component"},'component',_vm.$props,false)):_vm._e(),(!_vm.data.htmlContentOnTop && _vm.slides.length)?_c('el-carousel',{attrs:{"height":"800px","interval":5000,"arrow":"always"}},_vm._l((_vm.slides),function(item,index){return _c('el-carousel-item',{key:'slide-' + index},[_c('div',{domProps:{"innerHTML":_vm._s(item)}})])}),1):_vm._e()],1)]),(!_vm.data.htmlContentOnTop && _vm.data.formId)?_c('primary-button',{on:{"click":function($event){return _vm.navigate(_vm.data)}}},[_vm._v(_vm._s(_vm._f("translate")('general.view')))]):_vm._e()],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }